import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgHandicap = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="24" height="24" rx="2" fill="#003da5" />
    <path
      d="M19.2757 16.9925C19.1853 16.5043 18.7168 16.1818 18.2286 16.2716L17.3847 16.4198L16.4441 13.7508C16.3169 13.3925 15.9779 13.1532 15.5978 13.1529H10.6027V11.216H14.0492C14.5457 11.2053 14.9393 10.7947 14.929 10.2982C14.9189 9.81666 14.5308 9.42884 14.0492 9.41848H10.6027V8.76823C10.6025 8.27178 10.1998 7.86963 9.70333 7.86989C9.20741 7.87016 8.80525 8.27231 8.80499 8.76823V14.0648C8.80499 14.561 9.20768 14.9634 9.70386 14.9634H14.9393L15.9259 17.7602C16.0759 18.1857 16.5185 18.4338 16.9597 18.3403L18.5566 18.0388C19.044 17.948 19.366 17.4797 19.2759 16.992L19.2757 16.9925ZM14.6859 16.47C14.3425 16.3348 13.9541 16.4998 13.8131 16.8408C12.8289 19.1505 10.1586 20.2252 7.849 19.2408C6.22444 18.5485 5.14786 16.9782 5.08783 15.2134C5.10005 13.4626 6.14342 11.884 7.74913 11.1865C8.07797 11.0093 8.20096 10.5992 8.02378 10.2703C7.8668 9.97869 7.52149 9.84402 7.20832 9.95159C4.28485 11.1984 2.92538 14.579 4.17196 17.5025C5.09421 19.6655 7.23966 21.0497 9.59018 20.9987C11.9837 21.0173 14.1472 19.576 15.0522 17.3601C15.1996 17.0196 15.0432 16.6241 14.7029 16.4767C14.7027 16.4767 14.7021 16.4764 14.7019 16.4761L14.6859 16.47ZM9.70333 4.00133C10.6622 4 11.4408 4.77616 11.4421 5.73533C11.4434 6.69423 10.6673 7.47278 9.70811 7.47411C8.74921 7.47544 7.97066 6.69928 7.96933 5.74011C7.96933 5.73878 7.96933 5.73772 7.96933 5.73639C7.96933 4.77828 8.74549 4.00106 9.7036 4L9.70333 4.00133Z"
      fill="white"
    />
  </svg>
);

export default SvgHandicap;
