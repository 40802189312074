/* eslint-disable */
// THIS FILE IS AUTOGENERATED. DO NOT EDIT DIRECTLY
const Theme = {
  colors: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  borderRadius: {
    none: '0',
    sm: '0.125rem',
    md: '0.375rem',
    lg: '1rem',
    xl: '3.5rem',
    full: '9999px',
  },
  boxShadow: {
    sm: '0 0.00625rem 0.01875rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2)',
    DEFAULT: '0 0.01875rem 0.03125rem rgba(0, 0, 0, 0.1), 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2)',
    md: '0 0.03125rem 0.0625rem rgba(0, 0, 0, 0.09), 0 0.25rem 0.5rem rgba(0, 0, 0, 0.18)',
    lg: '0 0.0625rem 0.125rem rgba(0, 0, 0, 0.08), 0 0.5rem 1rem rgba(0, 0, 0, 0.16)',
    xl: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.07), 0 1rem 2rem rgba(0, 0, 0, 0.14)',
    '2xl': '0 0.25rem 0.5rem rgba(0, 0, 0, 0.06), 0 2rem 4rem rgba(0, 0, 0, 0.12)',
    none: 'none',
  },
  fontFamily: { sans: ['"NewsGothic"', 'Arial', 'sans-serif'] },
  fontSize: {
    xsMin: '0.75rem',
    xsMax: '0.75rem',
    smMin: '0.875rem',
    smMax: '0.875rem',
    mdMin: '1rem',
    mdMax: '1rem',
    lgMin: '1.125rem',
    lgMax: '1.125rem',
    xlMin: '1.25rem',
    xlMax: '1.6875rem',
    '2xlMin': '1.5rem',
    '2xlMax': '2.5rem',
    '3xlMin': '2rem',
    '3xlMax': '3.375rem',
  },
  fontWeight: { normal: '400', bold: '700' },
  height: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    76: '4.75rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    122: '7.625rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    190: '11.875rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    full: '100%',
    screen: '100vh',
  },
  letterSpacing: { normal: '0', sm: '0.01rem', md: '0.025rem', lg: '0.0625rem' },
  lineHeight: { none: '1', sm: '1.2', md: '1.6' },
  maxWidth: {
    0: '0',
    none: 'none',
    '2xs': '18.75rem',
    xs: '25rem',
    sm: '31.25rem',
    md: '37.5rem',
    lg: '41.875rem',
    xl: '50rem',
    '2xl': '56.25rem',
    '3xl': '60rem',
    '4xl': '75rem',
    '5xl': '100rem',
    '1/2': '50%',
    '3/5': '60%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    full: '100%',
  },
  screens: { sm: '40em', md: '48em', lg: '64em', xl: '80em', '2xl': '97em' },
  spacing: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  width: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    76: '4.75rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    122: '7.625rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    190: '11.875rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    '1/29': '29%',
    full: '100%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    '80v': '80vw',
    screen: '100vw',
    '1/7': '14.2857143%',
  },
  edsSizingFixed: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    76: '4.75rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    122: '7.625rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    190: '11.875rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    px: '1px',
  },
  containers: {
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
  },
  edsFontFace: false,
  aspectRatio: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    14: '14',
    15: '15',
    16: '16',
    '1/1': '1/1',
    '16/4': '16/4',
    '16/5': '16/5',
    '16/6': '16/6',
    '16/7': '16/7',
    '16/8': '16/8',
    '16/9': '16/9',
    '16/10': '16/10',
    '16/11': '16/11',
    '16/12': '16/12',
    '16/13': '16/13',
    '16/14': '16/14',
    '16/15': '16/15',
  },
  edsButtonStyle: {
    primary: {
      base: { background: '#00789e', border: '#00789e', text: '#fff' },
      hover: { background: '#005984', border: '#005984', text: '#fff' },
      active: {},
      focus: { outline: '#00789e' },
    },
    'primary-reversed': {
      base: { background: '#fff', border: '#fff', text: '#00789e' },
      hover: { background: '#e0f6fb', border: '#e0f6fb', text: '#00789e' },
      active: {},
      focus: { outline: '#fff' },
    },
    secondary: {
      base: { background: 'transparent', border: '#00789e', text: '#00789e' },
      hover: { background: '#f3fbfd', border: '#00789e', text: '#00789e' },
      active: {},
      focus: { outline: '#00789e' },
    },
    'secondary-reversed': {
      base: { background: 'transparent', border: '#fff', text: '#fff' },
      hover: { background: 'rgba(0, 0, 0, .1)', border: '#fff', text: '#fff' },
      active: {},
      focus: { outline: '#fff' },
    },
    tertiary: {
      base: { background: 'transparent', border: 'transparent', text: '#00789e' },
      hover: { background: '#f3fbfd', border: 'transparent', text: '#00789e' },
      active: {},
      focus: { outline: '#00789e' },
    },
    'tertiary-reversed': {
      base: { background: 'transparent', border: 'transparent', text: '#fff' },
      hover: { background: 'rgba(0, 0, 0, .1)', border: 'transparent', text: '#fff' },
      active: {},
      focus: { outline: '#fff' },
    },
    destructive: {
      base: { background: '#9e1b32', border: '#9e1b32', text: '#fff' },
      hover: { background: '#8B0E04', border: '#8B0E04', text: '#fff' },
      active: {},
      focus: { outline: '#9e1b32' },
    },
    'destructive-reversed': {
      base: { background: '#fff', border: 'transparent', text: '#9e1b32' },
      hover: { background: 'rgb(236,209,214)', border: 'transparent', text: '#9e1b32' },
      active: {},
      focus: { outline: '#9e1b32' },
    },
    flat: {
      base: { background: 'transparent', border: 'transparent', text: '#00789e', paddingX: '0' },
      hover: { background: 'transparent', border: 'transparent', text: '#00789e' },
      active: {},
      focus: { outline: '#00789e' },
    },
    'flat-reversed': {
      base: { background: 'transparent', border: 'transparent', text: '#fff', paddingX: '0' },
      hover: { background: 'transparent', border: 'transparent', text: '#fff' },
      active: {},
      focus: { outline: '#fff' },
    },
  },
  edsButtonSize: {
    '2xs': {
      font: '0.875rem',
      height: '2.25rem',
      iconLeading: '0.875rem',
      iconTrailing: '0.625rem',
      iconOnly: '1.125rem',
      iconTopFont: '0.75rem',
      iconTopIcon: '0.875rem',
      iconTopGap: '0.25rem',
      iconTopPaddingX: '0.375rem',
    },
    xs: {
      font: '1rem',
      height: '2.5rem',
      iconLeading: '1rem',
      iconTrailing: '0.75rem',
      iconOnly: '1.25rem',
      iconTopFont: '0.75rem',
      iconTopIcon: '1.125rem',
      iconTopGap: '0.25rem',
      iconTopPaddingX: '0.625rem',
    },
    sm: {
      font: '1rem',
      height: '3rem',
      iconLeading: '1rem',
      iconTrailing: '0.75rem',
      iconOnly: '1.5rem',
      iconTopFont: '0.75rem',
      iconTopIcon: '1.25rem',
      iconTopGap: '0.25rem',
      iconTopPaddingX: '0.75rem',
    },
    md: {
      font: '1rem',
      height: '3.5rem',
      iconLeading: '1.125rem',
      iconTrailing: '0.875rem',
      iconOnly: '1.75rem',
      iconTopFont: '0.875rem',
      iconTopIcon: '1.5rem',
      iconTopGap: '0.375rem',
      iconTopPaddingX: '0.875rem',
    },
    lg: {
      font: '1.125rem',
      height: '4rem',
      iconLeading: '1.25rem',
      iconTrailing: '1rem',
      iconOnly: '2rem',
      iconTopFont: '1rem',
      iconTopIcon: '1.75rem',
      iconTopGap: '0.375rem',
      iconTopPaddingX: '1rem',
    },
  },
  edsButtonWidth: { auto: 'auto', full: '100%' },
  edsButtonShape: { rounded: '0.375rem', pill: '9999px' },
  edsContainer: {
    0: '0',
    none: 'none',
    '2xs': '18.75rem',
    xs: '25rem',
    sm: '31.25rem',
    md: '37.5rem',
    lg: '41.875rem',
    xl: '50rem',
    '2xl': '56.25rem',
    '3xl': '60rem',
    '4xl': '75rem',
    '5xl': '100rem',
    '1/2': '50%',
    '3/5': '60%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
    full: '100%',
  },
  edsFormSize: {
    sm: {
      input: { height: '3rem', fontsize: '1rem', label: '0.25rem' },
      textarea: { height: '5rem', fontsize: '1rem' },
      radio: { inputsize: '1.125rem', inputMarginTop: '0.125rem', fontsize: '1rem' },
    },
    md: {
      input: { height: '3.5rem', fontsize: '1rem', label: '0.375rem' },
      textarea: { height: '8rem', fontsize: '1rem' },
      radio: { inputsize: '1.5rem', inputMarginTop: '-0.0625rem', fontsize: '1rem' },
    },
    lg: {
      input: { height: '4rem', fontsize: '1.125rem', label: '0.375rem' },
      textarea: { height: '11rem', fontsize: '1.125rem' },
      radio: { inputsize: '2rem', inputMarginTop: '0.0625rem', fontsize: '1.125rem' },
    },
  },
  edsFormStatus: { error: '#9e1b32', warning: '#f78e1e', success: '#00853f', loading: '#26bcd7' },
  edsIcon: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    30: '1.875rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    76: '4.75rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    122: '7.625rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    190: '11.875rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    px: '1px',
  },
  edsIdentifier: {
    'single-sm': '0.75rem',
    single: '1.25rem',
    'double-sm': '1.5rem',
    double: '2.5rem',
  },
  edsLink: { underline: 'currentColor', 'no-underline': 'transparent' },
  edsLinkCaret: {
    chevron: { height: '0.625em', marginLeft: 'clamp(6px, .375em, 8px)', marginRight: '0' },
    'chevron-back': { height: '0.75em', marginLeft: '0', marginRight: 'clamp(6px, .375em, 8px)' },
    arrow: { height: '0.375em', marginLeft: 'clamp(6px, .375em, 8px)', marginRight: '0' },
  },
  edsLogo: { 'de-sm': '1.625rem', de: '2.625rem', 'subbrand-sm': '2.125rem', subbrand: '3.625rem' },
  edsTypography: {
    xs: {
      fontSize: { min: '0.75rem', max: '0.75rem', calc: '0.75rem + 0vw' },
      lineHeight: '1.6',
      letterSpacing: '0.01rem',
    },
    sm: {
      fontSize: { min: '0.875rem', max: '0.875rem', calc: '0.875rem + 0vw' },
      lineHeight: '1.6',
      letterSpacing: '0.01rem',
    },
    md: {
      fontSize: { min: '1rem', max: '1rem', calc: '1rem + 0vw' },
      lineHeight: '1.6',
      letterSpacing: '0.025rem',
    },
    lg: {
      fontSize: { min: '1.125rem', max: '1.125rem', calc: '1.125rem + 0vw' },
      lineHeight: '1.4',
      letterSpacing: '0.025rem',
      fontSmoothing: true,
    },
    xl: {
      fontSize: {
        min: '1.25rem',
        max: '1.6875rem',
        calc: '0.7451923076923076rem + 1.682692307692308vw',
      },
      lineHeight: '1.3',
      letterSpacing: '0.0625rem',
      fontSmoothing: true,
    },
    '2xl': {
      fontSize: {
        min: '1.5rem',
        max: '2.5rem',
        calc: '0.34615384615384603rem + 3.8461538461538463vw',
      },
      lineHeight: '1.2',
      letterSpacing: '0.0625rem',
      fontSmoothing: true,
    },
    '3xl': {
      fontSize: {
        min: '2rem',
        max: '3.375rem',
        calc: '0.4134615384615383rem + 5.288461538461539vw',
      },
      lineHeight: '1.2',
      letterSpacing: '0.0625rem',
      fontSmoothing: true,
    },
    'xl-fixed': {
      fontSize: { min: '1.6875rem', max: '1.6875rem', calc: '1.6875rem + 0vw' },
      lineHeight: '1.2',
      letterSpacing: '0.0625rem',
      fontSmoothing: true,
    },
    '2xl-fixed': {
      fontSize: { min: '2.5rem', max: '2.5rem', calc: '2.5rem + 0vw' },
      lineHeight: '1.2',
      letterSpacing: '0.0625rem',
      fontSmoothing: true,
    },
    '3xl-fixed': {
      fontSize: { min: '3.375rem', max: '3.375rem', calc: '3.375rem + 0vw' },
      lineHeight: '1.2',
      letterSpacing: '0.0625rem',
      fontSmoothing: true,
    },
  },
  accentColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
    auto: 'auto',
  },
  animation: {
    none: 'none',
    spin: 'spin 1s linear infinite',
    ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
    pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    bounce: 'bounce 1s infinite',
    ripple: 'ripple 1s ease-in-out once',
    click: 'click 1s ease-out',
    loader: 'loaderIn 250ms ease-out, loaderOpen 5s ease-out 250ms',
    shake: 'shake 300ms ease-in-out',
    slide: 'slide 250ms ease-out',
    dotSlide: 'dotSlide 1.2s linear infinite',
  },
  aria: {
    busy: 'busy="true"',
    checked: 'checked="true"',
    disabled: 'disabled="true"',
    expanded: 'expanded="true"',
    hidden: 'hidden="true"',
    pressed: 'pressed="true"',
    readonly: 'readonly="true"',
    required: 'required="true"',
    selected: 'selected="true"',
  },
  backdropBlur: {
    0: '0',
    none: '0',
    sm: '4px',
    DEFAULT: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    '2xl': '40px',
    '3xl': '64px',
    xs: '2px',
  },
  backdropBrightness: {
    0: '0',
    50: '.5',
    75: '.75',
    90: '.9',
    95: '.95',
    100: '1',
    105: '1.05',
    110: '1.1',
    125: '1.25',
    150: '1.5',
    200: '2',
  },
  backdropContrast: { 0: '0', 50: '.5', 75: '.75', 100: '1', 125: '1.25', 150: '1.5', 200: '2' },
  backdropGrayscale: { 0: '0', DEFAULT: '100%' },
  backdropHueRotate: {
    0: '0deg',
    15: '15deg',
    30: '30deg',
    60: '60deg',
    90: '90deg',
    180: '180deg',
  },
  backdropInvert: { 0: '0', DEFAULT: '100%' },
  backdropOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  backdropSaturate: { 0: '0', 50: '.5', 100: '1', 150: '1.5', 200: '2' },
  backdropSepia: { 0: '0', DEFAULT: '100%' },
  backgroundColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  backgroundImage: {
    none: 'none',
    'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
    'gradient-to-tr': 'linear-gradient(to top right, var(--tw-gradient-stops))',
    'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
    'gradient-to-br': 'linear-gradient(to bottom right, var(--tw-gradient-stops))',
    'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
    'gradient-to-bl': 'linear-gradient(to bottom left, var(--tw-gradient-stops))',
    'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
    'gradient-to-tl': 'linear-gradient(to top left, var(--tw-gradient-stops))',
    'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
  },
  backgroundOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  backgroundPosition: {
    bottom: 'bottom',
    center: 'center',
    left: 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    right: 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    top: 'top',
  },
  backgroundSize: { auto: 'auto', cover: 'cover', contain: 'contain' },
  blur: {
    0: '0',
    none: '0',
    sm: '4px',
    DEFAULT: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  borderColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
    DEFAULT: 'currentColor',
    input: '#b4b4b4',
  },
  borderOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  borderSpacing: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  borderWidth: { 0: '0px', 2: '2px', 4: '4px', 6: '6px', 8: '8px', DEFAULT: '1px' },
  boxShadowColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  brightness: {
    0: '0',
    50: '.5',
    75: '.75',
    90: '.9',
    95: '.95',
    100: '1',
    105: '1.05',
    110: '1.1',
    125: '1.25',
    150: '1.5',
    200: '2',
  },
  caretColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  columns: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    auto: 'auto',
    '3xs': '16rem',
    '2xs': '18rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
  },
  container: {},
  content: { none: 'none' },
  contrast: { 0: '0', 50: '.5', 75: '.75', 100: '1', 125: '1.25', 150: '1.5', 200: '2' },
  cursor: {
    auto: 'auto',
    default: 'default',
    pointer: 'pointer',
    wait: 'wait',
    text: 'text',
    move: 'move',
    help: 'help',
    'not-allowed': 'not-allowed',
    none: 'none',
    'context-menu': 'context-menu',
    progress: 'progress',
    cell: 'cell',
    crosshair: 'crosshair',
    'vertical-text': 'vertical-text',
    alias: 'alias',
    copy: 'copy',
    'no-drop': 'no-drop',
    grab: 'grab',
    grabbing: 'grabbing',
    'all-scroll': 'all-scroll',
    'col-resize': 'col-resize',
    'row-resize': 'row-resize',
    'n-resize': 'n-resize',
    'e-resize': 'e-resize',
    's-resize': 's-resize',
    'w-resize': 'w-resize',
    'ne-resize': 'ne-resize',
    'nw-resize': 'nw-resize',
    'se-resize': 'se-resize',
    'sw-resize': 'sw-resize',
    'ew-resize': 'ew-resize',
    'ns-resize': 'ns-resize',
    'nesw-resize': 'nesw-resize',
    'nwse-resize': 'nwse-resize',
    'zoom-in': 'zoom-in',
    'zoom-out': 'zoom-out',
  },
  divideColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
    DEFAULT: 'currentColor',
    input: '#b4b4b4',
  },
  divideOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  divideWidth: { 0: '0px', 2: '2px', 4: '4px', 6: '6px', 8: '8px', DEFAULT: '1px' },
  dropShadow: {
    sm: '0 1px 1px rgb(0 0 0 / 0.05)',
    DEFAULT: ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)'],
    md: ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)'],
    lg: ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)'],
    xl: ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)'],
    '2xl': '0 25px 25px rgb(0 0 0 / 0.15)',
    none: '0 0 #0000',
  },
  fill: {
    none: 'none',
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  flex: { 1: '1 1 0%', auto: '1 1 auto', initial: '0 1 auto', none: 'none' },
  flexBasis: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
  },
  flexGrow: { 0: '0', DEFAULT: '1' },
  flexShrink: { 0: '0', DEFAULT: '1' },
  gap: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  gradientColorStops: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  gradientColorStopPositions: {
    '0%': '0%',
    '5%': '5%',
    '10%': '10%',
    '15%': '15%',
    '20%': '20%',
    '25%': '25%',
    '30%': '30%',
    '35%': '35%',
    '40%': '40%',
    '45%': '45%',
    '50%': '50%',
    '55%': '55%',
    '60%': '60%',
    '65%': '65%',
    '70%': '70%',
    '75%': '75%',
    '80%': '80%',
    '85%': '85%',
    '90%': '90%',
    '95%': '95%',
    '100%': '100%',
  },
  grayscale: { 0: '0', DEFAULT: '100%' },
  gridAutoColumns: { auto: 'auto', min: 'min-content', max: 'max-content', fr: 'minmax(0, 1fr)' },
  gridAutoRows: { auto: 'auto', min: 'min-content', max: 'max-content', fr: 'minmax(0, 1fr)' },
  gridColumn: {
    auto: 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    'span-full': '1 / -1',
  },
  gridColumnEnd: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    auto: 'auto',
  },
  gridColumnStart: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    auto: 'auto',
  },
  gridRow: {
    auto: 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    'span-full': '1 / -1',
  },
  gridRowEnd: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    auto: 'auto',
  },
  gridRowStart: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    auto: 'auto',
  },
  gridTemplateColumns: {
    1: 'repeat(1, minmax(0, 1fr))',
    2: 'repeat(2, minmax(0, 1fr))',
    3: 'repeat(3, minmax(0, 1fr))',
    4: 'repeat(4, minmax(0, 1fr))',
    5: 'repeat(5, minmax(0, 1fr))',
    6: 'repeat(6, minmax(0, 1fr))',
    7: 'repeat(7, minmax(0, 1fr))',
    8: 'repeat(8, minmax(0, 1fr))',
    9: 'repeat(9, minmax(0, 1fr))',
    10: 'repeat(10, minmax(0, 1fr))',
    11: 'repeat(11, minmax(0, 1fr))',
    12: 'repeat(12, minmax(0, 1fr))',
    none: 'none',
    subgrid: 'subgrid',
  },
  gridTemplateRows: {
    1: 'repeat(1, minmax(0, 1fr))',
    2: 'repeat(2, minmax(0, 1fr))',
    3: 'repeat(3, minmax(0, 1fr))',
    4: 'repeat(4, minmax(0, 1fr))',
    5: 'repeat(5, minmax(0, 1fr))',
    6: 'repeat(6, minmax(0, 1fr))',
    7: 'repeat(7, minmax(0, 1fr))',
    8: 'repeat(8, minmax(0, 1fr))',
    9: 'repeat(9, minmax(0, 1fr))',
    10: 'repeat(10, minmax(0, 1fr))',
    11: 'repeat(11, minmax(0, 1fr))',
    12: 'repeat(12, minmax(0, 1fr))',
    none: 'none',
    subgrid: 'subgrid',
    '0fr': '0fr',
    '1fr': '1fr',
  },
  hueRotate: { 0: '0deg', 15: '15deg', 30: '30deg', 60: '60deg', 90: '90deg', 180: '180deg' },
  inset: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    full: '100%',
  },
  invert: { 0: '0', DEFAULT: '100%' },
  keyframes: {
    spin: { to: { transform: 'rotate(360deg)' } },
    ping: { '75%, 100%': { transform: 'scale(2)', opacity: '0' } },
    pulse: { '50%': { opacity: '.5' } },
    bounce: {
      '0%, 100%': {
        transform: 'translateY(-25%)',
        animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
      },
      '50%': { transform: 'none', animationTimingFunction: 'cubic-bezier(0,0,0.2,1)' },
    },
    ripple: {
      '0%': { transform: 'scale(0, 0)', opacity: '1' },
      '40%': { transform: 'scale(30, 30)', opacity: '.5' },
      '100%': { transform: 'scale(40, 40)', opacity: '0' },
    },
    click: {
      '0%': { opacity: '.25', transform: 'scale(1)' },
      '100%': { opacity: '0', transform: 'scale(3)' },
    },
    loaderIn: {
      '0%': { opacity: '0', width: '60px', transform: 'scale(.6)' },
      '100%': { opacity: '1', width: '60px', transform: 'scale(1)' },
    },
    loaderOpen: { '0%': { width: '60px' }, '10%': { width: '60px' }, '100%': { width: '100%' } },
    shake: {
      '10%, 90%': { transform: 'translate3d(-1px, 0, 0)' },
      '20%, 80%': { transform: 'translate3d(2px, 0, 0)' },
      '30%, 50%, 70%': { transform: 'translate3d(-4px, 0, 0)' },
      '40%, 60%': { transform: 'translate3d(4px, 0, 0)' },
    },
    slide: {
      '0%': { transform: 'translateX(var(--slide-from, 100))' },
      '100%': { transform: 'translateX(var(--slide-to, 0))' },
    },
    dotSlide: {
      '0%': { opacity: '0', transform: 'scale(0.8)' },
      '20%': { opacity: '1', transform: 'translateX(21px) scale(1)' },
      '80%': { opacity: '1', transform: 'translateX(83px)' },
      '100%': { opacity: '0', transform: 'translateX(104px) scale(0.8)' },
    },
  },
  listStyleType: {
    none: 'none',
    disc: 'disc',
    decimal: 'decimal',
    alpha: 'lower-alpha',
    circle: 'circle',
    square: 'square',
    roman: 'lower-roman',
  },
  listStyleImage: { none: 'none' },
  margin: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  lineClamp: { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6' },
  maxHeight: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    none: 'none',
    full: '100%',
    screen: '100vh',
    svh: '100svh',
    lvh: '100lvh',
    dvh: '100dvh',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  minHeight: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    full: '100%',
    screen: '100vh',
    svh: '100svh',
    lvh: '100lvh',
    dvh: '100dvh',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  minWidth: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    full: '100%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  objectPosition: {
    bottom: 'bottom',
    center: 'center',
    left: 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    right: 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    top: 'top',
  },
  opacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  order: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
    first: '-9999',
    last: '9999',
    none: '0',
  },
  outlineColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  outlineOffset: { 0: '0px', 1: '1px', 2: '2px', 4: '4px', 8: '8px' },
  outlineWidth: { 0: '0px', 1: '1px', 2: '2px', 3: '3px', 4: '4px', 8: '8px' },
  padding: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  placeholderColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  placeholderOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  ringColor: {
    DEFAULT: '#3b82f6',
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  ringOffsetColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  ringOffsetWidth: { 0: '0px', 1: '1px', 2: '2px', 4: '4px', 8: '8px' },
  ringOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
    DEFAULT: '0.5',
  },
  ringWidth: {
    0: '0px',
    1: '1px',
    2: '2px',
    4: '4px',
    6: '6px',
    8: '8px',
    12: '12px',
    24: '24px',
    DEFAULT: '3px',
  },
  rotate: {
    0: '0deg',
    1: '1deg',
    2: '2deg',
    3: '3deg',
    6: '6deg',
    12: '12deg',
    45: '45deg',
    90: '90deg',
    180: '180deg',
  },
  saturate: { 0: '0', 50: '.5', 100: '1', 150: '1.5', 200: '2' },
  scale: {
    0: '0',
    50: '.5',
    75: '.75',
    90: '.9',
    95: '.95',
    100: '1',
    102: '1.02',
    105: '1.05',
    110: '1.1',
    125: '1.25',
    150: '1.5',
    flip: '-1',
  },
  scrollMargin: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  scrollPadding: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  sepia: { 0: '0', DEFAULT: '100%' },
  skew: { 0: '0deg', 1: '1deg', 2: '2deg', 3: '3deg', 6: '6deg', 12: '12deg' },
  space: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  stroke: {
    none: 'none',
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  strokeWidth: { 0: '0', 1: '1', 2: '2' },
  supports: {},
  data: {},
  textColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  textDecorationColor: {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000',
    white: '#fff',
    gray: {
      lighter: '#f2f4f4',
      light: '#dfe4e4',
      DEFAULT: '#d7d9da',
      dark: '#696969',
      darker: '#4c4c4c',
    },
    blue: { DEFAULT: '#005984', dark: '#014464' },
    teal: {
      lighter: '#f3fbfd',
      light: '#e0f6fb',
      DEFAULT: '#a7e1ea',
      dark: '#26bcd7',
      darker: '#00789e',
    },
    red: { DEFAULT: '#9e1b32', dark: '#8B0E04' },
    orange: { DEFAULT: '#f78e1e' },
    yellow: { DEFAULT: '#ffd200', dark: '#fdb913' },
    green: { DEFAULT: '#54b948', dark: '#00853f' },
    brown: '#6a4024',
  },
  textDecorationThickness: {
    0: '0px',
    1: '1px',
    2: '2px',
    4: '4px',
    8: '8px',
    auto: 'auto',
    'from-font': 'from-font',
  },
  textIndent: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
  },
  textOpacity: {
    0: '0',
    5: '0.05',
    10: '0.1',
    15: '0.15',
    20: '0.2',
    25: '0.25',
    30: '0.3',
    35: '0.35',
    40: '0.4',
    45: '0.45',
    50: '0.5',
    55: '0.55',
    60: '0.6',
    65: '0.65',
    70: '0.7',
    75: '0.75',
    80: '0.8',
    85: '0.85',
    90: '0.9',
    95: '0.95',
    100: '1',
  },
  textUnderlineOffset: { 0: '0px', 1: '1px', 2: '2px', 4: '4px', 8: '8px', auto: 'auto' },
  transformOrigin: {
    center: 'center',
    top: 'top',
    'top-right': 'top right',
    right: 'right',
    'bottom-right': 'bottom right',
    bottom: 'bottom',
    'bottom-left': 'bottom left',
    left: 'left',
    'top-left': 'top left',
  },
  transitionDelay: {
    0: '0s',
    75: '75ms',
    100: '100ms',
    150: '150ms',
    200: '200ms',
    300: '300ms',
    500: '500ms',
    700: '700ms',
    1000: '1000ms',
    3000: '3000ms',
  },
  transitionDuration: {
    0: '0s',
    75: '75ms',
    100: '100ms',
    150: '150ms',
    200: '200ms',
    300: '300ms',
    500: '500ms',
    700: '700ms',
    1000: '1000ms',
    DEFAULT: '150ms',
  },
  transitionProperty: {
    none: 'none',
    all: 'all',
    DEFAULT:
      'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
    colors: 'color, background-color, border-color, text-decoration-color, fill, stroke',
    opacity: 'opacity',
    shadow: 'box-shadow',
    transform: 'transform',
  },
  transitionTimingFunction: {
    DEFAULT: 'cubic-bezier(0.4, 0, 0.2, 1)',
    linear: 'linear',
    in: 'cubic-bezier(0.4, 0, 1, 1)',
    out: 'cubic-bezier(0, 0, 0.2, 1)',
    'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  translate: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    full: '100%',
  },
  size: {
    0: '0',
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    18: '1.125rem',
    20: '1.25rem',
    24: '1.5rem',
    28: '1.75rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    44: '2.75rem',
    48: '3rem',
    56: '3.5rem',
    64: '4rem',
    80: '5rem',
    96: '6rem',
    112: '7rem',
    128: '8rem',
    144: '9rem',
    160: '10rem',
    176: '11rem',
    192: '12rem',
    208: '13rem',
    224: '14rem',
    240: '15rem',
    256: '16rem',
    288: '18rem',
    320: '20rem',
    384: '24rem',
    auto: 'auto',
    px: '1px',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
    min: 'min-content',
    max: 'max-content',
    fit: 'fit-content',
  },
  willChange: {
    auto: 'auto',
    scroll: 'scroll-position',
    contents: 'contents',
    transform: 'transform',
  },
  zIndex: {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    auto: 'auto',
    hide: '-1',
    base: '0',
    dropdown: '100',
    popover: '200',
    tooltip: '300',
    banner: '400',
    nav: '500',
    header: '500',
    alert: '600',
    overlay: '900',
    modal: '950',
  },
};

// By only exporting the parts of the theme object we need to ref in react-land
// we can keep the rest of the object out the bundle generated by the build process
export const screens = Theme.screens;
export const colors = Theme.colors;
export const space = Theme.space;
