import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClipBoard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
    aria-labelledby={titleId}
    aria-hidden="true"
    focusable="false"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.997 9.292s-10.203 3.67-9.32 8.086l28.705-7.309c-1.31-4.325-12.061-2.65-12.061-2.65l-1.157-4.568a3.778 3.778 0 1 0-7.324 1.858zm1.98-7.736a1.889 1.889 0 1 1-.008.003l.009-.002Zm34.369 48.425L47.062 5.698a4.675 4.675 0 0 0-5.604-3.32l-10.66 2.726v.152c.822 0 1.858-.137 2.954-.137 5.436 0 8.634 1.523 9.502 4.386a1.994 1.994 0 0 1-1.348 2.478l-.069.019-28.674 7.309a1.98 1.98 0 0 1-2.437-1.523c-1.005-4.782 5.482-8.238 8.908-9.715v-.137l-10.66 2.726a4.57 4.57 0 0 0-3.319 5.603l11.284 44.283a4.57 4.57 0 0 0 5.513 3.32l32.527-8.284a4.57 4.57 0 0 0 3.366-5.604ZM15.707 27.58a1.188 1.188 0 0 1 1.523-1.797l2.65 2.33 3.152-7.766a1.192 1.192 0 0 1 2.208.898l-3.761 9.243c-.147.36-.459.627-.838.716h-.274c-.292 0-.574-.109-.792-.305zm3.853 12.868a1.188 1.188 0 0 1 1.523-1.797l2.634 2.33 3.152-7.766a1.192 1.192 0 0 1 2.208.898l-3.761 9.32a1.19 1.19 0 0 1-.838.716h-.274a1.2 1.2 0 0 1-.792-.305zm13.431 6.533-3.822 9.396a1.19 1.19 0 0 1-.838.716h-.274c-.292 0-.574-.109-.792-.305l-3.898-3.457a1.188 1.188 0 0 1 1.523-1.797l2.65 2.33 3.152-7.766a1.192 1.192 0 0 1 2.208.898z"
    />{' '}
  </svg>
);

export default SvgClipBoard;
