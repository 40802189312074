import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgPicnicShelter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width="24" height="24" rx="2" fill="currentColor" />
    <path
      d="M19.1311 15.1114H17.368L16.5566 13.103H17.582C17.7858 13.103 17.9509 12.9379 17.9509 12.7341V12.4777C17.9509 12.274 17.7858 12.1089 17.582 12.1089H12.418C12.2142 12.1089 12.0491 12.274 12.0491 12.4777V12.7323C12.0491 12.936 12.2142 13.1011 12.418 13.1011H13.4434L12.632 15.1095H10.8689C10.6651 15.1095 10.5 15.2746 10.5 15.4784V15.7329C10.5 15.9367 10.6651 16.1018 10.8689 16.1018H12.2336L11.4314 18.0843C11.3484 18.2872 11.448 18.4532 11.6527 18.4532H11.9829C12.2113 18.436 12.4114 18.2941 12.5029 18.0843L13.3015 16.1073H16.7004L17.499 18.0843C17.5907 18.2941 17.7908 18.436 18.0191 18.4532H18.3493C18.5522 18.4532 18.6517 18.2872 18.5706 18.0843L17.7665 16.1054H19.1313C19.335 16.1054 19.5001 15.9404 19.5001 15.7366V15.4821C19.5011 15.2783 19.3369 15.1124 19.1331 15.1114C19.1325 15.1114 19.1317 15.1114 19.1311 15.1114ZM13.7091 15.1114L14.5205 13.103H15.4943L16.2911 15.1114H13.7091Z"
      fill="white"
    />
    <path
      d="M21 4.5L5.5 11.935M2.5 13.2742L5.5 11.935M5.5 11.935V20.5H21.5"
      stroke="white"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgPicnicShelter;
